<template>
  <div class="footer mauto">
    <div class="w950 mauto ohidden">
      <div class="cotact fl">
        <div>合作电话 : 13959972140</div>
        <div>合作邮箱 : bijixia@foxmail.com</div>
        <div>公司地址 : 北京市海淀区学院路街道学院8号写字楼A座6层607</div>
      </div>
      <div class="global-footer-friend fr">
        <div>战略合作伙伴</div>
        <div class="f-list tr">
          <img src="~@/assets/images/f.jpg" alt />
        </div>
      </div>
    </div>
    <div class="c-btm-wrap">
      <div class="c-btm ohidden w950 mauto">
        <div class="fl pt5 flex">
          <router-link target="_blank" :to="{path: '/about'}">关于我们</router-link>
          <span class="split">|</span>
          <router-link target="_blank" :to="{path: '/contact'}">联系我们</router-link>
          <span class="split">|</span>
          <a href="https://www.lagou.com/gongsi/197663.html" target="_blank">招聘</a>
          <span class="split">|</span>
          <router-link target="_blank" :to="{path: '/contact'}">广告服务</router-link>
          <span class="split">|</span>
          <router-link target="_blank" :to="{path: '/disclaimer'}">免责声明</router-link>
          <span class="split">|</span>
          <span>厦门能量派信息科技有限公司</span>
          <span class="split">|</span>
          <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">闽ICP备16015212号</a>
        </div>
        <div class="fr">
          <img width="90" src="~@/assets/images/f-logo.png" alt />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
  background: #2f3134;
  color: #f2f2f2;
  font-size: 14px;
  padding-top: 40px;
  .cotact {
    width: 450px;
    opacity: 0.6;
    line-height: 32px;
    font-weight: bold;
  }
  .global-footer-friend {
    opacity: 0.6;
    width: 450px;
  }
  .f-list {
    padding: 20px 0 44px;
    overflow: hidden;
    img {
      width: 445px;
    }
  }
}
.c-btm-wrap {
  border-top: 1px solid #454749;
  padding: 28px 0 30px;
}
.c-btm {
  .flex {
    opacity: 0.6;
    color: #f2f2f2;
    a {
      color: #f2f2f2;
    }
    .split {
      padding: 0 16px;
    }
    display: flex;
  }
}
</style>