<template>
  <div class="c-header">
    <div class="c-top-bar w950 mauto">
      <router-link class="c-logo" target="_blank" :to="{path: '/'}"></router-link>
      <div class="pt5">
        <router-link class="c-btn" :to="{path: '/download'}" target="_blank">下载App</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.c-header {
  background: #fff;
  box-shadow: 1px 1px 2px rgb(0 0 0 / 10%);
  .c-top-bar {
    padding: 16px 0;
    display: flex;
    justify-content: space-between;
  }
  .c-logo {
    display: block;
    width: 119px;
    height: 39px;
    background: url('~@/assets/images/logo.png') no-repeat 0 0;
    background-size: 100% 100%;
  }
  .c-btn {
    display: block;
    width: 100px;
    height: 30px;
    line-height: 30px;
    border: 1px solid #cc1111;
    color: #cc1111;
    text-decoration: none;
    border-radius: 15px;
    text-align: center;
  }
}
</style>